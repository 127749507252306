<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="65%"
    :close-on-click-modal="false"
    :show-close="false"
    @close="handleClose">
    <div>
      <div class="main" v-loading="loading">
        <el-card class="card-1">
          <el-form class="left">
            <div class="title">
              <div><img :src="result.customPicUrl ? result.customPicUrl : 'https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/4FE9D265B1EF406FA50B208FE59A6A34.png'" alt=""></div>
              <div>{{result.customName}}</div>
              <div>会员号: {{result.vipCode}}</div>
            </div>
            <p><span>机构: </span>{{result.companyName}}</p>
            <p><span>预约类型: </span>{{result.tasktypeName}}</p>
            <p><span>产品类型: </span>{{result.taskName}}</p>
            <p><span>预约时间: </span>{{result.samplingTime}}</p>
            <p><span>医生: </span>{{result.docterName}}</p>
            <p><span>营养师: </span>{{result.dietitianName}}</p>
            <p><span>护士: </span>{{result.nurseName}}</p>
            <p><span>运动师: </span>{{result.fitcouchName}}</p>
            <!-- <p><span>采样方式: </span>{{result.samplingName}}</p> -->
          </el-form>
        </el-card>
        <div class="right">
          <el-card class="card-2">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="到访状态">
                <el-select :disabled="onlyLook" v-model="form.region" placeholder="请选择到访状态">
                  <el-option v-for="(item,index) in list" :key="index" :label="item.name" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card class="progress">
            <!-- <el-steps align-center>
              https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/4FE9D265B1EF406FA50B208FE59A6A34.png
              <el-step v-for="(item,index) in result.samplingTaskHistoryVos" :key="index" :title="item.processName" :description="item.time"></el-step>
            </el-steps> -->
            <div class="visitTitle">到访历史:</div>
            <div class="outBox" v-for="(item, index) in result.samplingTaskHistoryVos" :key="index">
              <div class="line"></div>
              <div class="word">
                <div class="outPoint" :style="outPoint(index)"><div class="point" :style="point(index)"></div></div>&nbsp;&nbsp;
                <span class="time">{{item.time}}</span>&nbsp;&nbsp;
                <span>{{item.processName}}</span>
              </div>
              <div class="line"></div>
            </div>
          </el-card>
        </div>
        
      </div>
      <div class="footer">
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureCommit">确 定</el-button>
        </span>
      </div>
    </div>
   
  </el-dialog>
</template>

<script>
import { postRequest, postRequestAnthor } from '@/api'
export default {
  data () {
    return {
      dialogVisible: true,
      loading: true,
      form: {
        region: ''
      },
      result: {},
      list: [ // 101新单201待确认预约301已确认预约
        {
          code: '101',
          name: '新单'
        },
        {
          code: '2011',
          name: '待移动端确认'
        },
        {
          code: '201',
          name: '待确认预约'
        },
        {
          code: '301',
          name: '已确认预约'
        },
        {
          code: '303',
          name: '到店'
        },
        {
          code: '304',
          name: '离店'
        },
        {
          code: '401',
          name: '已采样'
        },
        {
          code: '501',
          name: '已送检'
        },
        {
          code: '601',
          name: '已完成'
        },
      ],
      outerPoints:['border: 1px solid #A9D900','border: 1px solid #5764F1','border: 1px solid #FF8F00','border: 1px solid #FF8B00','border: 1px solid #FF0000','border: 1px solid pink','border: 1px solid #A9D900','border: 1px solid #5764F1','border: 1px solid #FF8F00','border: 1px solid #FF8B00','border: 1px solid #FF0000','border: 1px solid pink'],
      points:['background: #A9D900;','background:  #5764F1;','background: #FF8F00;','background:  #FF8B00;','background: #FF0000;','background: pink;','background: #A9D900;','background:  #5764F1;','background: #FF8F00;','background:  #FF8B00;','background: #FF0000;','background: pink;']
    }
  },
  props: ['info', 'onlyLook'],
  mounted(){
    if (this.info != '') {
      postRequestAnthor('/samplingComputer/querySamplingInfo',{id: this.info.id}).then(res=>{
        console.log(res, '查询结果')
        this.loading = false
        res[0].samplingTaskHistoryVos = res[0].samplingTaskHistoryVos.reverse()
        if (res[0].catalogCode == 'DP') {
          res[0].catalogName = '单品'
        } else if (res[0].catalogCode == 'HJ') {
          res[0].catalogName = '会籍'
        }else if (res[0].catalogCode == 'Tj') {
          res[0].catalogName = '体检'
        }
        this.list.map((item, index) => {
          if (item.code == res[0].substatus) {
           this.list= this.list.slice(index)
          }
        })
        this.result = res[0]
        for(let i = 0; i < this.list.length; i++) {
          if (this.list[i].code == this.result.substatus) {
            this.list = this.list.slice(i)
            this.form.region = this.list[0].code
          }
        }
      }).catch(()=>{
        this.loading = false
      })
    }
  },
  methods: {
    handleClose () {
      this.$emit('closeVisit')
    },
    outPoint(i){
      return this.outerPoints[i]
    },
    point(i){
      return this.points[i]
    },
    sureCommit () {
      if (this.onlyLook == true) {
        this.handleClose()
      } else {
        let data = {
          id: this.info.id,
          subStatus: this.form.region,
          samplingType: this.result.samplingType,
          oldStatus: this.result.status,
          oldSubStatus: this.result.substatus,
          updateBy: '2'
        }
        postRequestAnthor('/samplingComputer/updateSamplingStatus', data).then(res=>{
          console.log(res)
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.handleClose()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// img {
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
// }
.el-select {
  width: 100% !important;
}
.main {
  display: flex;
  justify-content: space-between;
  margin-top: -30px;
  .card-1 {
    width: 35%;
    margin-right: 20px;
    .left {
      .title{
        text-align: center;
        margin: 0 auto;
        :first-child {
          height: 90px;
          margin-bottom: 29px;
          img {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            padding: 0;
          }
        }
        :nth-child(2) {
          font-size: 16px;
          color: #323232;
          margin-bottom: 13px;
        }
        :last-child {
          font-size: 14px;
          color: #676767;
          padding-bottom: 23px;
          border-bottom: 1px solid #E5E5E5;
          margin-bottom: 29px;
        }
      }
      p {
        span {
          width: 100px;
          display: inline-block;
          text-align: justify;
        }
      }
      .el-row {
        display: flex;
        align-items: center;
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card-2 {
      margin-bottom: 25px;
    }
    .progress {
      display: flex;
      padding: 20px;
      background: #fff;
      height: 100%;
      border-radius: 5px;
      .el-steps {
        margin-top: 40px;
      }
    }
  }
}
.footer {
  padding-top: 30px;
  text-align: center;
}
.outBox {
  margin: 0 auto;
  margin-left: 80px;
}
.word {
  display: flex;
  align-items: center;
  margin: 0 auto;
  .outPoint {
    border: 1px solid;
    border-radius: 50%;
    .point {
      margin: 2px;
      width: 4px;
      height: 4px;
      background: #999;
      border-radius: 50%;
    }
  }
  .time {
    color: #999;
  }
}
.line {
    width: 1px;
    height: 20px;
    background: #E6E6E6;
    margin: -5px 4px -5px;
  }
  .visitTitle {
    font-size: 14px;
    width: 61px;
    color: #444;
    text-align: center;
    margin: 20px 0px;
  }
</style>