<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small">
            <el-form-item label="客户姓名:">
              <el-input
                v-model="form.customerName"
                :clearable="true"
                placeholder="请输入客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户电话:">
              <el-input
                v-model="form.customerPhone"
                :clearable="true"
                placeholder="请输入客户电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="到访日期:">
              <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="任务状态:">
              <el-select clearable v-model="form.region" @change="changeState" placeholder="请选择任务状态">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="(item, index) in list" :key="index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" :disabled="disabledSearch" @click="getAllVisitTask">查询</el-button>
            </el-form-item>
            <!-- <el-form-item>
              <el-button type="danger" @click="handleDelete">删除</el-button>
            </el-form-item> -->
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div class="table-block">
      <el-table v-loading="loading" :data="tableData" :header-cell-style="{'text-align': 'center', 'background': '#EEF1FC', 'color': '#999'}" :cell-style="{'text-align':'center'}" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column prop="customName" label="客户姓名"> </el-table-column>
        <el-table-column prop="subStatusName" label="任务状态"> </el-table-column>
        <el-table-column prop="tel1" label="手机号"> </el-table-column>
        <el-table-column prop="docterName" width="80" label="医护人员"> </el-table-column>
        <el-table-column prop="tasktypeName" width="100" label="到访类型"> </el-table-column>
        <el-table-column prop="samplingTime" label="到访时间"> </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status != 6" @click="addNew(scope.row)" type="text" size="small">详情</el-button>
            <el-button v-else @click="lookFor(scope.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      background>
    </el-pagination>
    </el-card>
    <visitDialog :info="info" :onlyLook="onlyLook" @closeVisit="closeVisit" v-if="showMailDialog"></visitDialog>
  </div>
</template>

<script>
import visitDialog from '@/views/appointmentManagement/components/visitDialog'
import { postRequest, postRequestAnthor } from '@/api'
import { formatTime } from '@/utils/utils'
export default {
  data () {
    return {
      form: {
        customerPhone: '',
        customerName: '',
        date1: '',
        region: ''
      },
      tableData: [],
      loading: true,
      disabledSearch: false,
      multipleSelection: [],
      showMailDialog: false,
      pageNo: 1,
      pageSize: 10,
      total: null,
      onlyLook: '',
      info: '',
      list: [
        {
          code: '303',
          name: '到店'
        },
        {
          code: '304',
          name: '离店'
        },
        {
          code: '401',
          name: '已采样'
        },
        {
          code: '501',
          name: '已送检'
        },
        {
          code: '601',
          name: '已完成'
        },
      ]
    }
  },
  components: { visitDialog },
  mounted(){
    this.getAllVisitTask()
  },
  methods: {
    handleSelectionChange(val) {
      console.log(123)
      this.multipleSelection = val
    },
    addNew(val){
      console.log(val)
      this.showMailDialog = true
      this.info = val
      this.onlyLook = false
    },
    lookFor(val){
      this.showMailDialog = true
      this.info = val
      this.onlyLook = true
    },
    closeVisit () {
      this.showMailDialog = false
      this.getAllVisitTask()
    },
    changeState(val){
      console.log('修改数组', val)
      if (val) {
        this.status = []
        this.status.push(val)
      } else {
        this.status = [3, 4, 5, 6]
      }
    },
    handleDelete () {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消删除'
        });
      });
    },
    getAllVisitTask () {
      this.loading = true
      this.disabledSearch = true
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        customerName: this.form.customerName ? this.form.customerName : '',
        customerTel: this.form.customerPhone ? this.form.customerPhone : '',
        samplStartTime: this.form.date1 ? formatTime(this.form.date1) : '',
        subStatus: this.form.region ? this.form.region : '',
        samplingType: [2],
        status: [3, 4, 5, 6]
      }
      postRequestAnthor('/samplingComputer/querySamplingInfoPageList', data).then(res=>{
        this.disabledSearch = false
        console.log(res, '到访任务')
        this.loading = false
        this.total = res.count
        this.tableData = res.data
      }).catch(()=>{
        this.loading = false
        this.disabledSearch = false
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getAllVisitTask()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.getAllVisitTask()
    }
  }
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
// .searchFrom > .el-form-item {
//   margin-right: 4px !important;
// }
</style>
